import '../sass/styles.sass'

const conversionsURL = `${PROTOCOL}://${HOST}/v1/conversion/`

export const getSource = () => {
  /*
   * Extracts the `advref` query paramter from the URL,
   * if it exists.  This is where the `TrackingLinkSource.source`
   * relevant to the conversion will be stored.
   *
   * In a successful conversion cycle, this value should be POSTed
   * to the `/v1/conversions/` endpoint
   */
  const queryParams = new URLSearchParams(window.location.search)
  return queryParams.get('advref')
}

export const postJSON = (url, json) => fetch(url, {
  method: 'post',
  headers: {
    Accept: 'application/json, text/plain, */*',
    'Content-Type': 'application/json',
  },
  body: JSON.stringify(json),
})

export const runConversion = () => {
  /*
   * Main entry point for the app.
   */
  const source = getSource()

  if (source) {
    postJSON(conversionsURL, { source })
  }
}
