import $ from 'jquery'
import { runConversion } from './conversions'
import { validZips } from './rawZips'

// Helper Functions
function getURLParameter(name, defaultValue) {
  const param = decodeURI((RegExp(`${name}=(.+?)(&|$)`, 'i').exec(location.search) || [null, ''])[1])
  return (!param && defaultValue) ? defaultValue : param
}

/** Returns a unique visitor ID if available; undefined otherwise. */
function getVisitorId() {
  let visitorId = ''
  if (window.ga && ga.getAll) {
    const trackers = ga.getAll()
    if (trackers.length > 0) {
      visitorId = trackers[0].get('clientId')
    }
  }
  return visitorId
}

$(document).ready(() => {
  // animate quotes
  $('.fade3').fadeOut(0)
  $('.fade4').fadeOut(0)
  setInterval(() => {
    $('.fade1')
      .delay(6000)
      .fadeOut(500)
      .delay(6000)
      .fadeIn(500)
    $('.fade2')
      .delay(3000)
      .fadeOut(500)
      .delay(6000)
      .fadeIn(500)
      .delay(3000)
    $('.fade3')
      .delay(6000)
      .fadeIn(500)
      .delay(6000)
      .fadeOut(500)
    $('.fade4')
      .delay(3000)
      .fadeIn(500)
      .delay(6000)
      .fadeOut(500)
      .delay(3000)
  }, 1000)
  // Handle good2gozipsubmit submission
  $('.error-message').hide()
  $('#good2gozipsubmit').submit((e) => {
    e.preventDefault()

    const zip = $('#zipCode').val()
    // handle invalid zip and break
    if (!/\d{5}/.test(zip)) {
      $('.error-message').show()
      return
    }
    if (!validZips.includes(zip)) {
      $('.error-message').show()
      return
    }

    // submit valid zip
    const redirectHostname = 'http://leads.goodtogoquote.com/switch/web_route'
    const source = getURLParameter('utm_source')
    const medium = getURLParameter('utm_medium')
    const campaign = getURLParameter('utm_campaign')
    const content = getURLParameter('utm_content')
    const visitorId = getVisitorId()

    // trigger event for Google Analytics Tracking
    const event = {
      eventType: 'zipFormSubmitted',
      eventData: {
        zip,
      },
    }

    window.eventQueue = window.eventQueue || []

    eventQueue.push(event)

    // trigger conversion to Advocate campaign
    runConversion()

    // set cookie so that Quote - start event is tracked on direct.good2go.com
    document.cookie = 'quoteStart=true;domain=.good2go.com;path=/'

    const redirectUrl = `${redirectHostname}?zipcode=${zip}&source_medium=${encodeURIComponent(`${source}/${medium}`)}&ga_visitor_id=${encodeURIComponent(visitorId)}&_ga=${encodeURIComponent(visitorId)}&utm_campaign=${encodeURIComponent(campaign)}&utm_content=${encodeURIComponent(content)}`

    window.setTimeout(() => {
      window.location.href = redirectUrl
    }, 250)
  })
})
